import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pengisian: "",
      nama: "",
      no_wa: "",
      email: "",
      id: "",
      isSubmitSuccessful: false,
      isSubmitLoading: false,
      isSubmitError: false,
    };
  }

  isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  handleDataInvestorChange = (event) => {
    this.setState({ pengisian: event.target.value });
  };

  handleNamaInvestorChange = (event) => {
    this.setState({ nama: event.target.value });
  };

  handleWAInvestorChange = (event) => {
    this.setState({ no_wa: event.target.value });
  };

  handleEmailInvestorChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handleSubmit1 = (event) => {
    event.preventDefault();
  
    // Check if the form is valid before proceeding
    if (!this.isFormValid()) {
      alert("Data belum lengkap.");
      return;
    }

    if (!this.isEmailValid(this.state.email)) {
      alert("Format email salah.");
      return;
    }
  
    // Prepare the request body
    const requestBody = {
      id: 0,
      pengisian: this.state.pengisian,
      nama: this.state.nama,
      no_wa: this.state.no_wa,
      email: this.state.email,
    };
  
    // Display loader
    this.setState({ isSubmitLoading: true });
  
    // Make the first API request
    fetch("https://apigw-ext.bankmuamalat.co.id/v1/pds/main/save", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.ok) {
          return response.json(); // Parse the response body as JSON
        } else {
          throw new Error("Request failed");
        }
      })
      .then((data) => {
        // Extract the 'id' from the response data
        const idFromResponse = data.id;
        console.log(this.state.pengisian);
        // Set the state of 'id' with the id from response data post
        this.setState({ id: idFromResponse });
  
        // Prepare the request body for the second API call
        const secondApiRequestBody = {
          pengisian: this.state.pengisian,
          id: idFromResponse,
        };
  
        // Make the second API request with 'id' as part of the request body
        return fetch(
          "https://apigw-ext.bankmuamalat.co.id/v1/pds/email/sent/" +
            this.state.pengisian +
            "/" +
            idFromResponse,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      })
      .then((response) => {
        if (response.ok) {
          console.log(this.state.pengisian);
          alert(
            "[SUKSES] Data Berhasil Terkirim. Harap Cek Email Anda Secara Berkala."
          );
          console.log("Second API call success");
          // Handle the successful second API call here if needed
        } else {
          throw new Error("Second API call failed");
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isSubmitError: true, isSubmitLoading: false });
      })
      .finally(() => {
        // Regardless of success or failure, hide the loader
        this.setState({ isSubmitLoading: false });
      });
  };
  
  isFormValid = () => {
    const { pengisian, nama, no_wa, email } = this.state;
    return pengisian && nama && no_wa && email;
  };

  render() {

    return (
      <section id="contact">
        <Fade bottom duration={1000}>
          <div className="row section-head">
            <div className="two columns header-col">
              <h1>
                <span>Get In Touch.</span>
              </h1>
            </div>

            <div className="ten columns">
              <p className="lead">Isi data dibawah ini dan cek email anda secara berkala untuk mendapatkan link akses pengisian form pembaruan pemegang saham Bank Muamalat Indonesia.</p>
            </div>
          </div>
        </Fade>

        <div className="row">
          <Slide left duration={1000}>
            <div className="eight columns">
              <form onSubmit={this.handleSubmit1}>
                <fieldset>
                  <div>
                    <label htmlFor="pengisian" className="radio-label">
                      Tipe Investor <span className="required">*</span>
                    </label>
                    <div className="radio-group">
                      <input
                        type="radio"
                        id="individu"
                        name="pengisian"
                        value="Perorangan"
                        checked={this.state.pengisian === "Perorangan"}
                        onChange={this.handleDataInvestorChange}
                        className="radio-input"
                      />
                      <label htmlFor="individu" className="radio-option">
                        Individu
                      </label>
                    </div>
                    <label hidden htmlFor="pengisian" className="radio-label"> </label>
                    <div className="radio-group">
                      <input
                        type="radio"
                        id="nonIndividu"
                        name="pengisian"
                        value="Non-Perorangan"
                        checked={this.state.pengisian === "Non-Perorangan"}
                        onChange={this.handleDataInvestorChange}
                        className="radio-input"
                      />
                      <label htmlFor="nonIndividu" className="radio-option">
                        Non-Individu
                      </label>
                    </div>
                  </div>
                  
                  {
                    this.state.pengisian == "Perorangan" && (
                      <>
                        <div>
                    <label htmlFor="nama">
                      Nama Lengkap (sesuai KTP) <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      value={this.state.nama}
                      size="35"
                      id="nama"
                      name="nama"
                      onChange={this.handleNamaInvestorChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="email">
                      Email <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      value={this.state.email}
                      size="35"
                      id="email"
                      name="email"
                      onChange={this.handleEmailInvestorChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="no_wa">Nomor WA<span className="required">*</span></label>
                    <input
                      type="text"
                      defaultValue=""
                      value={this.state.no_wa}
                      size="35"
                      id="no_wa"
                      name="no_wa"
                      onChange={this.handleWAInvestorChange}
                    />
                  </div>
                      </> 
                    )
                  }

{
                    this.state.pengisian == "Non-Perorangan" && (
                      <>
                        <div>
                    <label htmlFor="nama">
                      Nama Institusi (sesuai perizinan) <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      value={this.state.nama}
                      size="35"
                      id="nama"
                      name="nama"
                      onChange={this.handleNamaInvestorChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="email">
                      Email (contact person)<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      value={this.state.email}
                      size="35"
                      id="email"
                      name="email"
                      onChange={this.handleEmailInvestorChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="no_wa">Nomor WA (contact person)<span className="required">*</span></label>
                    <input
                      type="text"
                      defaultValue=""
                      value={this.state.no_wa}
                      size="35"
                      id="no_wa"
                      name="no_wa"
                      onChange={this.handleWAInvestorChange}
                    />
                  </div>
                      </> 
                    )
                  }
                  

                  <div>
                    <button onClick={this.handleSubmit1} className="submit" disabled={ this.state.isSubmitLoading}>
                      {this.state.isSubmitLoading ? "Submitting..." : "Submit"}
                    </button>
                    <span id="image-loader">
                      <img alt="" src="images/loader.gif" />
                    </span>
                  </div>
                </fieldset>
              </form>

              {this.state.isSubmitError && <div id="message-warning">Error boy</div>}
              {this.state.isSubmitSuccessful && (
                <div id="message-success">
                  <i className="fa fa-check"></i>Your message was sent, thank you!
                  <br />
                </div>
              )}
            </div>
          </Slide>
        </div>
      </section>
    );
  }
}

export default Contact;
