import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <BrowserRouter basename={'/saham'}> {/* Add BrowserRouter with basename */}
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();

